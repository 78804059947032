import { useEffect } from "react";

import {
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

import { Post, HTTPError } from "api";
import { useAuth0 } from "@auth0/auth0-react";

const databaseStruct = (formData) => {
  return {
    "name": formData.name,
    "mysql": {
      "username": formData.username,
      "password": formData.password,
      "hostport": formData.hostport,
    },
  };
};

export const connectionInitialState = {
  connection: { valid: false, error: '', inProgress: false },
};

export function connectionReducer(state, action) {
  if (action.type == 'VALIDATED') {
    return {
      ...state, connection: { valid: action.valid, error: action.error, inProgress: false },
    };
  } else if (action.type == 'VALIDATE') {
    return {
      ...state,
      connection: { valid: false, inProgress: true, error: '' }
    }
  }
  return null;
}

export function ConnectionForm({ form, dispatch, state }) {
  const auth = useAuth0();
  const {
    register,
    formState: { errors, touchedFields },
    watch,
  } = form;
  const watchedValues = watch();

  // Test given db connection.
  useEffect(() => {
    // no point in testing an invalid or missing connection.
    if (errors.hostport != undefined || errors.username != undefined || Object.keys(touchedFields).length == 0) {
      return;
    }
    let cancelled = false;
    (async () => {
      // we wait a second for more input which will cancel this request.
      // if we weren't cancelled then we validate.
      dispatch({ type: 'VALIDATE' });
      await sleep(1000);
      if (cancelled) {
        return;
      }
      try {
        await Post(
          auth,
          '/v1/database/testconnection',
          databaseStruct(watchedValues))
        if (cancelled) {
          return;
        }
        dispatch({ type: 'VALIDATED', valid: true, error: '' });
      } catch (e) {
        if (cancelled) {
          return;
        }
        console.log('failed to validate', e);
        if (e instanceof HTTPError) {
          dispatch({ type: 'VALIDATED', valid: false, error: e.http_body.message });
        } else {
          dispatch({ type: 'VALIDATED', valid: false, error: 'Could not validate' });
          throw e;
        }
      }
    })();
    return () => {
      cancelled = true;
    }
  }, [watchedValues.username, watchedValues.password, watchedValues.hostport]);

  let validation = <div></div>;
  if (state.connection.inProgress) {
    validation = <div><Spinner animation="grow" variant="primary" /></div>;
  } else if (state.connection.error != '') {
    validation = (
      <Form.Control.Feedback type="invalid" style={{ "display": "inline", "fontSize": "100%" }}>
        <div className="cross-form" style={{}}>X</div>
        &nbsp;&nbsp;There was a problem with your connection information: {state.connection.error}
      </Form.Control.Feedback>);
  } else if (state.connection.valid) {
    validation = <div>
      <div className="tick-form" style={{}}>✓</div>
      &nbsp;&nbsp;<b>Connection Information Validated</b>
    </div>;
  }

  return <>
    <fieldset>
      <Form.Group>
        <Row>
          <Col className="control-label" sm="3">
            Hostport
          </Col>
          <Col>
            <Form.Control
              type="text"
              name="hostport"
              isInvalid={!!errors.hostport}
              {...register("hostport", { pattern: /.+:[0-9]+/, required: true })} />
            <Form.Text className="text-muted">
              Hostport of the target database.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.hostport && 'Hostport is required. Needs to be a host followed by a port. e.g. rliable.com:3306 or 192.168.1.1:3306'}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
    </fieldset>
    <fieldset>
      <Form.Group>
        <Row>
          <Col className="control-label" sm="3">
            Username
          </Col>
          <Col>
            <Form.Control
              type="text"
              name="username"
              isInvalid={!!errors.username}
              {...register("username", { required: true })} />
            <Form.Text className="text-muted">
              Username of the target database. Requires replica permission.
            </Form.Text>
            <Form.Control.Feedback type="invalid">{errors.username && 'Username is required'}</Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
    </fieldset>
    <fieldset>
      <Form.Group>
        <Row>
          <Col className="control-label" sm="3">
            Password
          </Col>
          <Col>
            <Form.Control
              type="password"
              name="password"
              {...register("password")} />
            <Form.Text className="text-muted">
              Password of the target database. Requires replica permission.
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
    </fieldset>
    <fieldset>
      <Form.Group>
        <Row>
          <Col>
            {validation}
          </Col>
        </Row>
      </Form.Group>
    </fieldset>
  </>;
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}