import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import React, { Alert, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { createBrowserHistory } from 'history';

import Loading from "components/Loading.js";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/all.css";

import { Get } from "api.js";

import OnboardLayout from "layouts/Onboard.js";
import AddDatabaseLayout from "layouts/AddDatabase.js";
import DatabaseDetailLayout from "layouts/DatabaseDetail";
import DatabaseCreateRestoreLayout from "layouts/DatabaseCreateRestore";
import DatabaseRestoreLayout from "layouts/DatabaseRestore";
import DatabaseLayout from "layouts/Database.js";
import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AccountUpgradeLayout from "layouts/AccountUpgrade";
import { HTTPError } from "api";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const history = createBrowserHistory();
const SentryRoute = Sentry.withSentryRouting(Route);


const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

function Index() {
  const auth = useAuth0();
  const { isAuthenticated, isLoading, loginWithRedirect, user } = auth;
  const [databases, setDatabases] = useState([])
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  let history = useHistory();

  // Make sure we have logged in before continuing.
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  const loadAccount = async () => {
    const account = await Get(auth, '/v1/account');
    Sentry.setUser({
      id: account.id,
      email: user.email,
    });
    account.reload = loadAccount;

    setAccount(account);

    let content = await Get(auth, '/v1/database')
    console.log('Databases, ', content.databases);
    setDatabases(content.databases);
    setLoading(false);
    return account;
  }

  useEffect(() => {
    // we wait for authentication before continuing. or if we have already loaded the account.
    if (!isAuthenticated || account != null)
      return;
    (async () => {
      try {
        await loadAccount();
      } catch (e) {
        // If we cannot load the account because of a NOT_FOUND error
        setLoading(false);
        if (e instanceof HTTPError && e.code == 404) {
          // redirect to onboarding flow.
          if (history.location.pathname != "/onboard") {
            history.push('/onboard');
          }
          return
        } else {
          setError(e);
          throw (e);
        }
      }
    })();
  }, [isAuthenticated, account]);


  if (isLoading || loading) {
    if (error) {
      return <Alert variant={danger}>
        Unable to load account. Refresh page or contact support at the bottom left of the page.
        {error}
      </Alert>;
    }
    return <Loading />;
  }
  let routes = [
    {
      path: "/database/add",
      name: "Add Database",
      layout: "",
      icon: "nc-icon nc-single-copy-04",
      component: null,
    },
  ];
  if (account == null) {
    routes = [];
  }

  return (
    <div className="wrapper">
      <div className="main-panel">
        <AdminNavbar account={account} />
        <Sidebar
          routes={routes}
          databases={databases}
        />
        <div className="content">
          <Switch>
            <SentryRoute path="/database/add" render={(props) => <AddDatabaseLayout {...props} />} />
            <SentryRoute path="/database/:database_id/backup/:backup_id/create_restore" render={(props) => <DatabaseCreateRestoreLayout {...props} />} />
            <SentryRoute path="/database/:database_id/restore/:restore_id" render={(props) => <DatabaseRestoreLayout {...props} />} />
            <SentryRoute path="/database/:id" render={(props) => <DatabaseDetailLayout {...props} />} />
            <SentryRoute path="/database" render={(props) => <DatabaseLayout {...props} />} />
            <SentryRoute path="/account/upgrade" render={(props) => <AccountUpgradeLayout account={account} {...props} />} />
            <SentryRoute path="/onboard" render={(props) => <OnboardLayout loadAccount={loadAccount} {...props} />} />
            <Redirect from="/" to="/database" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

/*Sentry.init({
  dsn: "https://02c10306d6814f5d8a9fc6a73328e5bd@o1128425.ingest.sentry.io/6171142",
  integrations: [new Integrations.BrowserTracing({
    // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  })],
  environment: process.env.REACT_APP_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/



function ErrorBoundary({ children }) {
  if (process.env.SENTRY != 'off') {
    return <Sentry.ErrorBoundary
      showDialog={true}
      fallback={<p>An error has occurred</p>}>
      {children}
    </Sentry.ErrorBoundary>;
  }
  return <>{children}</>;
}

ReactDOM.render(
  <Auth0Provider
    domain="citizencode.eu.auth0.com"
    clientId="y13L4KYWLAL1TrbxqZxuE7KAiaZi76IL"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <BrowserRouter history={history}>
      <Index />
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById("root")
);
