import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Link, useHistory } from 'react-router-dom';

import Loading from "components/Loading";
import { DatabaseStatus } from "components/DatabaseStatus";

import { Get } from "api.js";
import {
    Badge,
    Button,
    Card,
    Container,
    Col,
    Row,
    Table,
} from "react-bootstrap";

function DatabaseList(props) {
    let history = useHistory();
    let auth = useAuth0();
    const [databases, setDatabases] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        (async () => {
            let content = await Get(auth, '/v1/database')
            console.log('Databases, ', content.databases);
            content.databases
            setDatabases(content.databases);
            setLoading(false);
        })();
    }, []);
    if (loading) {
        return <Loading />;
    }

    const onRowClick = (id) => {
        history.push('/database/' + id);
    }

    const rows = databases.map((database) => {
        return (
            <tr
                key={database.id}
                onClick={() => onRowClick(database.id)}
                className="d-flex">
                <td className="col-2">
                    <DatabaseStatus database={database} />
                </td>
                <td className="col-5">
                    {database.name}
                </td>
                <td className="col-5">
                    {database.mysql.hostport}
                </td>
            </tr>);
    })

    return (
        <>
            <h1 style={{ marginTop: "5px" }}>Databases
                <Link to="/database/add" style={{ marginLeft: "50px" }}>
                    <Button variant="primary" className="btn-round">
                        <i className="nc-icon nc-simple-add"></i>&nbsp;Add Database
                    </Button>
                </Link>
            </h1>
            <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-responsive p-o">
                    <Table className="table-hover table-striped">
                        <thead>
                            <tr className="d-flex">
                                <th className="col-2"></th>
                                <th className="col-5">Name</th>
                                <th className="col-5">Host</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card >
        </>
    )
}

function DatabaseLayout(props) {
    return (
        <div className="content">
            <Container fluid>
                <Row>
                    <Col md="12">
                        <DatabaseList />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DatabaseLayout;