import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { humanizeSize } from "common.js"
import humanizeDuration from 'humanize-duration';

import mysqlLogo from 'assets/img/mysql-logo.png';

import { Get, Delete, Patch } from "api.js";

const {
  Badge,
  Button,
  Card,
  Container,
  Col,
  Form,
  Row,
  Table,
  Modal,
} = require("react-bootstrap");

import Loading from "components/Loading";
import { DatabaseStatus, BackupStatus } from "components/DatabaseStatus"

function DatabaseDetail({ database }) {
  let storageLocation = {
    "australia-southeast1": "Australia",
    "southamerica-east1": "Brazil",
    "europe-west3": "Germany",
    "asia-east2": "Hong Kong",
    "asia-south1": "India",
    "asia-northeast1": "Japan",
    "europe-west6": "Switzerland",
    "europe-west2": "UK",
    "us-central1": "US Central",
    "us-east1": "US East",
    "us-west1": "US West",
  }[database.storage[0].location]
  return (
    <>
      <div style={{ paddingBottom: "30px" }}>
        <img src={mysqlLogo} width="100px" style={{ paddingTop: "14px", paddingRight: "30px", float: "left" }} />
        <div style={{ float: "left" }}>
          <h1 style={{ margin: "0" }}>
            {database.name}
            <Button variant="primary" className="btn-round" style={{ marginLeft: "50px" }}>
              <i className="nc-icon icon-bold nc-simple-add"></i>&nbsp;Edit
            </Button>
            <ToggleBackupButton database={database} />
            <DeleteDatabaseButton database={database} />
          </h1>
          <Card.Subtitle>
            MySql Database
          </Card.Subtitle>
        </div>
        <div className="clearfix"></div>
      </div>
      <Card>
        <Card.Body>
          <b>General</b>
          <Table>
            <tbody>
              <tr>
                <th width="40%">Status</th>
                <th width="60%"><h3 style={{ margin: "0" }}><DatabaseStatus database={database} /></h3></th>
              </tr>
              <tr>
                <th>MySQL Host</th>
                <th>{database.mysql.hostport}</th>
              </tr>
              <tr>
                <th>MySQL Username</th>
                <th>{database.mysql.username}</th>
              </tr>
              <tr>
                <th>MySQL Password</th>
                <th>***</th>
              </tr>
            </tbody>
          </Table>

          <b>Backup Policy</b>
          <Table>
            <tbody>
              <tr>
                <th width="40%">
                  Backup Destination
                </th>
                <th width="60%">
                  {storageLocation}
                </th>
              </tr>
              <tr>
                <th>Full Backup Frequency</th>
                <th>{"Every " + humanizeDuration(database.dumpPeriodInSeconds * 1000)}</th>
              </tr>
              <tr>
                <th>Backup Retention Period</th>
                <th>{humanizeDuration(database.retentionInSeconds * 1000)}</th>
              </tr>
              <tr>
                <th>
                  Encryption
                </th>
                <th>
                  Disabled
                </th>
              </tr>
              <tr>
                <th>Binlog Replication</th>
                <th>Disabled</th>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

function DeleteDatabaseButton({ database }) {
  const auth = useAuth0();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const updateStatus = (status) => {
    return async () => {
      await Patch(auth, '/v1/database/' + database.id, { "status": status });
      database.reload();
    };
  };
  let toggleBackupButton = (
    <Button variant="warning" className="btn-round" style={{ marginLeft: "50px" }} onClick={() => { setShowConfirmation(true); }}>
      <i className="nc-icon icon-bold nc-button-pause"></i>&nbsp;Stop Backups
    </Button>);
  let message = "Are you sure you would like to delete all backups and stop backing up this database?";
  let newStatus = "DELETED_BACKUPS";

  const closeModal = () => { setShowConfirmation(false); };
  return <>
    <Button variant="danger" className="btn-round" style={{ marginLeft: "10px" }} onClick={() => { setShowConfirmation(true); }}>
      <i className="nc-icon icon-bold nc-simple-remove"></i>&nbsp;Stop and Delete Backups
    </Button>
    <Modal show={showConfirmation} onHide={closeModal}>
      <Modal.Header>
        <h2>Confirmation</h2>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={updateStatus(newStatus)}>Confirm</Button>
        <Button variant="secondary" onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  </>;
}

function ToggleBackupButton({ database }) {
  const auth = useAuth0();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const updateStatus = (status) => {
    return async () => {
      await Patch(auth, '/v1/database/' + database.id, { "status": status, "dump_error_count": 0 });
      database.reload();
    };
  };
  let toggleBackupButton = (
    <Button variant="warning" className="btn-round" style={{ marginLeft: "50px" }} onClick={() => { setShowConfirmation(true); }}>
      <i className="nc-icon icon-bold nc-button-pause"></i>&nbsp;Stop Backup
    </Button>);
  let message = "Are you sure you want to stop future backups? You can restart them in future once they have stopped.";
  let newStatus = "STOPPED_BACKUPS";

  if (database.status != 'HEALTHY') {
    message = "Are you sure you want to restart backups?"
    newStatus = "HEALTHY";
    toggleBackupButton = (
      <Button variant="primary" className="btn-round" style={{ marginLeft: "50px" }} onClick={() => { setShowConfirmation(true); }}>
        <i className="nc-icon icon-bold nc-button-play"></i>&nbsp;Start Backup
      </Button>);

  }
  const closeModal = () => { setShowConfirmation(false); };
  return <>
    {toggleBackupButton}
    <Modal show={showConfirmation} onHide={closeModal}>
      <Modal.Header>
        <h2>Confirmation</h2>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={updateStatus(newStatus)}>Confirm</Button>
        <Button variant="secondary" onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  </>;
}

function BackupDetail({ database, backups }) {
  backups.sort((a, b) => { return b.id - a.id });
  let backupData = backups.map((backup) => {
    let restoreButton = <></>;
    if (backup.status == "COMPLETED") {
      const destination = "/database/" + database.id + "/backup/" + backup.id + "/create_restore";
      restoreButton =
        <Link to={destination}>
          <Button>Restore</Button>
        </Link>;
    }
    let created = new Date(backup.created).toLocaleString();
    return (<tr key={backup.id}>
      <td style={{ width: "5%" }}><BackupStatus backup={backup} /></td>
      <td style={{ width: "10%" }}>{created}</td>
      <td style={{ width: "20%" }}>{humanizeSize(backup.backupSize)}</td>
      <td style={{ width: "30%" }}>{backup.errorMessage}</td>
      <td style={{ width: "15%" }}>{backup.backupDuration}</td>
      <td style={{ width: "10%" }}><DeleteBackupButton database={database} backup={backup} />{restoreButton}</td>
      <td></td>
    </tr>);
  });
  return (
    <Card>
      <Card.Header style={{ paddingBottom: "10px" }}>
        <Card.Title as="h2">
          Backups
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="table-hover">
          <thead>
            <tr>
              <th>Status</th>
              <th>Created At</th>
              <th>Size</th>
              <th>Notes</th>
              <th>Execution Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {backupData}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

function DeleteBackupButton({ database, backup }) {
  const auth = useAuth0();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const deleteBackup = async () => {
    const path = `/v1/database/${database.id}/backup/${backup.id}`;
    console.log('deleting')
    await Delete(auth, path);
    database.reload();

  };
  return <>
    <Modal show={showConfirmation} onHide={() => { setShowConfirmation(false); }}>
      <Modal.Header>
        <h2>Confirm Delete</h2>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this backup? It is not possible to recover once you have deleted it.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={deleteBackup}>Delete Backup</Button>
        <Button variant="secondary" onClick={() => { setShowConfirmation(false) }}>Close</Button>
      </Modal.Footer>
    </Modal>
    <Button variant="danger" onClick={() => { setShowConfirmation(true) }}>Delete</Button>
  </>;
}

function DatabaseDetailLayout(props) {
  const { id: databaseId } = useParams();

  const [loading, setLoading] = useState(true);
  const [database, setDatabase] = useState({});
  const auth = useAuth0();

  const loadDatabase = (async () => {
    setLoading(true);
    var database = await Get(auth, '/v1/database/' + databaseId)
    console.log('Database, ', database);
    database.reload = loadDatabase;
    setDatabase(database);
    setLoading(false);
  });

  useEffect(() => {
    loadDatabase();
  }, [databaseId]);

  if (loading) {
    return (<Loading />)
  }

  return <div className="content">
    <Container fluid>
      <Row>
        <Col md="12">
          <DatabaseDetail database={database} />
          <BackupDetail database={database} backups={database.backup} />
        </Col>
      </Row>
    </Container>
  </div>;
}

export default DatabaseDetailLayout;