import React from "react";

// react-bootstrap components
import {
  Button,
  Navbar,
  Container,
} from "react-bootstrap";

import { Link } from "react-router-dom";

function AdminNavbar({ account }) {
  console.log('account', account);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  if (account == null) {
    return <Navbar />;
  }
  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Link to="/account/upgrade"><span style={{
            "fontWeight": 500,
            "color": "#000000",
            "fontSize": "1.2em"

          }}>Upgrade Account ({account.accountPackage.package.name})</span></Link>

        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
