import { Button, Form, Container, Jumbotron } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "react-hook-form";

import { Post } from "api.js";
import { useHistory } from "react-router";

export default function OnboardLayout({ loadAccount }) {
  const auth = useAuth0();
  const { user } = auth;
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm();
  const history = useHistory();

  async function submit(formData) {
    console.log('submitted data', formData)
    await Post(auth, "/v1/account", { "organization_name": formData['project_name'] })
    loadAccount();
    history.replace('/account/upgrade?onboard=1')
  }

  // Set a default value to the project name field only after the user has clicked it.
  let isFirstClick = true;
  function handleProjectNameClick(event) {
    if (!isFirstClick) {
      return;
    }
    isFirstClick = false;
    setValue("project_name", user.name + "'s Project");
    event.target.select();
  }
  return (
    <Container fluid>
      <Jumbotron>
        <h1 style={{ fontSize: "80px", textAlign: "center" }}><b>Welcome to rliable</b></h1>
        <p style={{ fontSize: "30px" }}>We are almost ready to get started.</p>
        <p style={{ fontSize: "30px" }}>Let us create your first project. Projects let you group and share resources with team members.</p>
        <Form
          onSubmit={handleSubmit(submit)}
          style={{ width: "100%" }}
          className="form-horizontal">
          <fieldset>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                style={{
                  fontSize: "50px",
                  marginBottom: "10px",
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                type="text"
                name="project_name"
                onClick={handleProjectNameClick}
                placeholder={"Project Name e.g. '" + user.name + "'s Wordpress Site'"}
                {...register("project_name")}
              />
            </div>
          </fieldset>
          <fieldset>
            <Button
              style={{ fontSize: "30px" }}
              variant="primary"
              onClick={handleSubmit}
              type="submit">
              Create
            </Button>
          </fieldset>
        </Form>
      </Jumbotron>
    </Container>);
}