import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Get } from "api.js";
import { useEffect, useState } from "react";
import {
  Badge,
  Card,
  Col,
  Row,
  Form
} from "react-bootstrap";
import Loading from "components/Loading";

function RestoreStatus({ status }) {
  if (status == "PENDING") {
    return <Badge pill variant="secondary">Pending</Badge>;
  } else if (status == "IN_PROGRESS") {
    return <Badge pill variant="primary">In Progress</Badge>;
  } else if (status == "COMPLETED") {
    return <Badge pill variant="success">Done</Badge>;
  } else if (status == "ERROR") {
    return <Badge pill variant="danger">Error</Badge>;
  }

  return <Badge pill variant="warning">
    Unknown Status
  </Badge>;
}

export default function DatabaseRestore() {
  const { database_id: databaseId, restore_id: restoreId } = useParams();
  const auth = useAuth0();
  const [loading, setLoading] = useState(true);
  const [restore, setRestore] = useState({});

  useEffect(() => {
    let intervalId = setInterval(() => {
      (async () => {
        var restore = await Get(auth, '/v1/database/' + databaseId + '/restore/' + restoreId)
        console.log('Restore, ', restore);
        setRestore(restore);
        if (loading) {
          setLoading(false);
        }
      })();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [databaseId, restoreId]);

  if (loading) {
    return <>
      <h1 style={{ marginTop: "5px" }}>Restoring Backup</h1>
      <Loading />
    </>;
  }
  return <>
    <h1 style={{ marginTop: "5px" }}>Restoring Backup</h1>
    <Card>
      <Card.Body>
        <h2 style={{ marginTop: "10px" }}>Status</h2>
        <Form className="form">
          <Form.Group>
            <Row>
              <Col className="control-label" md="3">
              </Col>
              <Col md="8">
                <RestoreStatus status={restore.status} /> {restore.progressPercentage}%
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col className="control-label" md="3">
                Started
              </Col>
              <Col md="8">
                {restore.created}
              </Col>
            </Row>
          </Form.Group>
          <h2 style={{ marginTop: "10px" }}>Restore Target</h2>
          <Form.Group>
            <Row>
              <Col className="control-label" md="3">
                Hostport
              </Col>
              <Col md="8">
                <Form.Control
                  controlid="hostport"
                  size="lg"
                  type="text"
                  value={restore.target.hostport}
                  readOnly
                ></Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col className="control-label" md="3">
                Username
              </Col>
              <Col md="8">
                <Form.Control
                  size="lg"
                  type="text"
                  value={restore.target.username}
                  readOnly
                ></Form.Control>
              </Col>
            </Row>
          </Form.Group>
        </Form>

      </Card.Body>
    </Card>
  </>;
}