const {
  Button,
  Card,
  Container,
  Col,
  Form,
  Spinner,
  Row,
  Modal,
} = require("react-bootstrap");
import { useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAuth0 } from '@auth0/auth0-react';

import { Post } from "api.js";

import mysqlLogo from 'assets/img/mysql-logo.png';
import postgresqlLogo from 'assets/img/postgresql-logo.png';
import { ConnectionForm, connectionInitialState, connectionReducer } from "components/DatabaseConnection";


function reducer(state, action) {
  const connectionState = connectionReducer(state, action);
  if (connectionState != null) {
    return connectionState;
  }
  if (action.type == 'SUBMITTING') {
    return { ...state, submitting: true };
  }
  throw new Error('unknown action ' + JSON.stringify(action));
}

const initialState = {
  ...connectionInitialState,
  connection: { valid: false, error: '', inProgress: false },
  submitting: false,
};

function AddDatabaseForm() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const form = useForm({ mode: 'onChange' });
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = form;
  const auth = useAuth0();
  const [showComingSoon, setComingSoon] = useState(false);
  let history = useHistory();

  const databaseStruct = (formData) => {
    return {
      "name": formData.name,
      "mysql": {
        "username": formData.username,
        "password": formData.password,
        "hostport": formData.hostport,
      },
      "dump_period_in_seconds": formData["backup-dump-frequency"],
      "retention_in_seconds": formData["backup-retention"],
      "storage": [{
        "provider": "GCS",
        "location": formData["backup-country"]
      }],
    };
  }

  const onSubmit = (formData) => {
    // we can only submit if validation has completed successfully.
    if (!state.connection.valid) {
      trigger();
      return;
    }
    dispatch({ type: 'SUBMITTING' });
    (async () => {
      let submitData = databaseStruct(formData);
      let content = await Post(auth, '/v1/database', submitData);
      console.log('got content', content);
      history.push('/database/' + content.id);
    })();
  }

  let submitButton = <Button
    disabled={false}
    style={{ marginRight: "10px" }}
    type="submit"
    variant="primary">
    Add Database
  </Button>;
  if (state.submitting) {
    submitButton = <Button variant="primary">
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ marginRight: "10px" }}
      />
      Adding...
    </Button>;
  }

  return (
    <Card>
      <Card.Body>
        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
          <h2 style={{ marginTop: "10px" }}>General</h2>
          <fieldset>
            <Form.Group>
              <Row>
                <Col className="control-label" sm="3">
                  Name
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="name"
                    isInvalid={!!errors.name}
                    {...register("name", { required: true })} />
                  <Form.Text className="text-muted">
                    Name that we can use to identify the database.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.name && 'Name is required. '}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <fieldset>
            <Form.Group>
              <Row>
                <Col className="control-label" sm="3">
                  Database type
                </Col>
                <Col sm="3"><div className="select-database"><img height="150px" src={mysqlLogo} /></div></Col>
                <Col sm="3">
                  <img height="150px" src={postgresqlLogo} onClick={() => { setComingSoon(true); }} />
                </Col>
                <Modal show={showComingSoon} onHide={() => { setComingSoon(false); }}>
                  <Modal.Header><h2 style={{ "margin": "0" }}>Coming Soon</h2></Modal.Header>
                  <Modal.Body>
                    PostgreSQL support is coming soon. Contact Support at the bottom right for an updated roadmap.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => { setComingSoon(false); }}>Close</Button>
                  </Modal.Footer>
                </Modal>
              </Row>
            </Form.Group>
          </fieldset>

          <h2 style={{ marginTop: "10px" }}>Connection Information</h2>
          <ConnectionForm form={form} dispatch={dispatch} state={state} />
          <h2 style={{ marginTop: "10px" }}>Backup Policy</h2>
          <fieldset>
            <Form.Group>
              <Row>
                <Col className="control-label" sm="3">
                  Backup Physical Destination
                </Col>
                <Col>
                  <Form.Control
                    name="backup-country"
                    as="select"
                    defaultValue="us-central1"
                    {...register("backup-country")}
                  >
                    <option value="australia-southeast1">Australia</option>
                    <option value="southamerica-east1">Brazil</option>
                    <option value="europe-west3">Germany</option>
                    <option value="asia-east2">Hong Kong</option>
                    <option value="asia-south1">India</option>
                    <option value="asia-northeast1">Japan</option>
                    <option value="europe-west6">Switzerland</option>
                    <option value="europe-west2">UK</option>
                    <option value="us-central1">US Central</option>
                    <option value="us-east1">US East</option>
                    <option value="us-west1">US West</option>
                  </Form.Control>
                  <Form.Text className="text-muted">
                    Physical country your backups should be stored in.
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <fieldset>
            <Form.Group>
              <Row>
                <Col className="control-label" sm="3">
                  Enable Binlog Replication (Point in time restore)
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Enable binlog replication"
                    {...register("enable-binlog-replication")}
                  />
                  <Form.Text className="text-muted">
                    In addition to the full dumps should we download your binlogs to allow point in time restore and closer to zero recovery points.
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <fieldset>
            <Form.Group>
              <Row>
                <Col className="control-label" sm="3">
                  Full database dump frequency
                </Col>
                <Col>
                  <Form.Control
                    name="backup-dump-frequency"
                    as="select"
                    defaultValue="86400"
                    {...register("backup-dump-frequency")}
                  >
                    <option value="3600">Every 1 hour</option>
                    <option value="7200">Every 2 hours</option>
                    <option value="43200">Every 12 hours</option>
                    <option value="86400">Every 24 hours</option>
                    <option value="172800">Every 2 days</option>
                    <option value="604800">Every 7 days</option>
                    <option value="2592000">Every 30 days</option>
                  </Form.Control>
                  <Form.Text className="text-muted">
                    How frequently should we take a complete dump of your database.
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <fieldset>
            <Form.Group>
              <Row>
                <Col className="control-label" sm="3">
                  Database dump retention
                </Col>
                <Col>
                  <Form.Control
                    {...register("backup-retention")}
                    defaultValue="86400"
                    name="backup-retention"
                    as="select">
                    <option value="3600">1 hour</option>
                    <option value="7200">2 hours</option>
                    <option value="86400">1 day</option>
                    <option value="172800">2 days</option>
                    <option value="604800">1 week</option>
                    <option value="1209600">2 weeks</option>
                    <option value="2592000">1 month</option>
                  </Form.Control>
                  <Form.Text className="text-muted">
                    How long should we keep your backups before deleting them.
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <fieldset>
            <Form.Group>
              <Row>
                <Col className="control-lable">
                  {submitButton}
                </Col></Row>
            </Form.Group>
          </fieldset>
        </Form>
      </Card.Body>
    </Card>);
}

function AddDatabaseLayout(props) {
  return (<>
    <h1 style={{ marginTop: "10px", marginBottom: "30px" }}>Add Database</h1>
    <AddDatabaseForm />
  </>);
}

export default AddDatabaseLayout;