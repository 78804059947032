import {
  Button,
  Container,
  Card,
  Table,
  Modal,
} from "react-bootstrap";
import Loading from "components/Loading.js";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import 'assets/css/account-upgrade.css';
import { Get, Post } from "api";

function UpgradeButton({ account, plan }) {
  const [clicked, setClicked] = useState(false);

  // If we are looking at the 'current' plan then there's no work to be done.
  if ((account.accountPackage != null && account.accountPackage.package.id == plan.id) ||
    (account.accountPackage == null && plan.name == "Free Plan")) {
    return <Button>Current Plan</Button>
  }

  if (!clicked) {
    var text = "Upgrade";
    if (account.accountPackage != null && parseInt(account.accountPackage.package.cost) > parseInt(plan.cost)) {
      text = "Downgrade";
    }

    return <Button onClick={async () => {
      let paddleSubscriptionId = account.accountPackage.paddleSubscriptionId;
      if (paddleSubscriptionId != null && paddleSubscriptionId != "") {
        alert('Please contact support to update your account at support@rliable.com');
        return;
      }
      Paddle.Checkout.open({
        product: plan.paddlePlanId,
        email: account.email,
        passthrough: JSON.stringify({ "organization_id": account.organizationId }),
        successCallback: () => {
          // hacks on hacks on hacks.
          setTimeout(() => { account.reload(); }, 1000);

        },
        closeCallback: () => {
          setClicked(false);
        }
      });
      setClicked(true);
    }}>{text}</Button>;
  }

  return <Button>Upgrading...</Button>;
}

function AccountUpgrade({ account, packages }) {
  // better mapping to package names.
  for (var i in packages) {
    packages[packages[i].name] = packages[i];
  }

  console.log('account', account);
  if (account.accountPackage != null && account.accountPackage.state == 'PENDING') {
    return <Modal show={true} onHide={() => { }}>
      <Modal.Header>Waiting for pending Paddle transaction</Modal.Header>
      <Modal.Body>There was an ongoing Paddle transaction that we are awaiting the results for.
        Contact support if this dialog does not go away support@rliable.com</Modal.Body>
    </Modal>;
  }

  return (
    <Container fluid>
      <h1 style={{ textAlign: "center" }}>Upgrade Your Account</h1>
      <Card>
        <Card.Body>
          <Table className="accountUpgrade" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th></th>
                <th>
                  <h3>Free</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price">0</span> / mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Free Plan"]} />
                  <div></div>
                </th>
                <th>
                  <h3>Startup</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price" >7 </span>/ mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Startup Plan"]} />
                </th>
                <th>
                  <h3>Pro</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price">70</span> / mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Pro Plan"]} />
                </th>
                <th>
                  <h3>Enterprise</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price">500</span> / mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Enterprise Plan"]} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="featureName">Storage</th>
                <th>100 MiB</th>
                <th>1 GiB</th>
                <th>10 GiB</th>
                <th>100 GiB</th>
              </tr>
              <tr>
                <th className="featureName">Rliable Multi-Region Storage</th>
                <th>
                  <div>—</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
              </tr>
              <tr>
                <th className="featureName">Encryption at Rest</th>
                <th>
                  <div>—</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
              </tr>
              <tr>
                <th className="featureName">Binlog Streaming</th>
                <th>
                  <div>—</div>
                </th>
                <th>
                  <div>-</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
              </tr>
              <tr>
                <th className="featureName">Backup Frequency</th>
                <th>
                  <div>Up to Daily</div>
                </th>
                <th>
                  <div>Up to Hourly</div>
                </th>
                <th>
                  <div>Up to Once a Minute</div>
                </th>
                <th>
                  <div>Up to Once a Minute</div>
                </th>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}

function AccountUpgradeLayout({ account }) {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth0();

  useEffect(async () => {
    const packages = (await Get(auth, "/v1/account/packages")).packages;
    setPackages(packages);
    setLoading(false);
    console.log('packages', packages);
  }, []);


  if (account == null || packages == null || loading) return <Loading />;

  return (
    <div className="content">
      <AccountUpgrade account={account} packages={packages} />
    </div>
  );
}

export default AccountUpgradeLayout;